import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import router from '@router'
import 'vant/lib/index.css';
import { Dialog } from 'vant';




import mandMobile from 'mand-mobile'
import 'mand-mobile/lib/mand-mobile.css'
Vue.config.productionTip = false

Vue.use(Vant);

// 全局注册Vant的Dialog
Vue.use(Dialog);

Vue.use(mandMobile)
//自定义全局的后台请求
import $http from '@network/default'
Vue.prototype.$http = $http
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
