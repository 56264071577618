import Vue from 'vue'
import axios from "axios";
import router from "@router"
import { Dialog } from 'vant';
// 全局注册Vant的Dialog
Vue.use(Dialog);

let APIURL = process.env.VUE_APP_BASE_API

// let APIURL = window.location.href;
// const index = APIURL.indexOf("tenserpay")
// APIURL = process.env.NODE_ENV === 'production' ? APIURL.substring(0, index + 14).replace("gate-qf", "sys-qf") : process.env.VUE_APP_BASE_API;

const service = axios.create({
    // baseURL: process.env.NODE_ENV === 'production' ? APIURL  : '/', // api 的 base_url,
    baseURL:'/',
    timeout: 20000
})

console.log(process.env.VUE_APP_BASE_API);
console.log(window.location.href);
console.log(process.env.NODE_ENV);
const urlList = ['enterpriseAgreement','mpSys'] // 需要前面拼接

// 请求拦截器
service.interceptors.request.use(
    // 请求前是否需要配置什么
    config => {
        let arr = config.url.split('/')
    // 如果存在 就拼接admin
    let str = arr[0] ? arr[0] : arr[1]
    if(str.includes('file')){
      // config.url = '/common/' + config.url
      config.url = `${arr[0] ? '/common/' : '/common'}${config.url}`
    }else{
      let flag = urlList.includes(str)
      if(flag){
        // config.url = '/admin/' + config.url
        config.url = `${arr[0] ? '/admin/' : '/admin'}${config.url}`
      }
    }
        // TODO 比如：让每个请求携带自定义token 请根据实际情况自行修改
        // if (getToken()) {
        //     config.headers['Authorization'] = getToken()
        // }
        config.headers['Authorization'] = window.localStorage.getItem('token')
        config.headers['Content-Type'] = 'application/json'
        return config
    },
    // 报错提示
    error => {
        Dialog.alert({
            title: '错误',
            message: error,
        }).then(() => {
            // on close
        });
    }
)


// 响应拦截器
service.interceptors.response.use(
    response => {
        // TODO 根据响应码做进一步判断，比如页面跳转、报错、退出重登等等
        const code = response.status ? response.status : response.data.code
        if (code == 200) {
            return response.data
        }
        switch (code) {
            case 400:

                break;
            case 401:
                Dialog.alert({
                    title: '错误',
                    message: '认证过期，请重新登录',
                }).then(() => { });
                break;
            case 403:
                Dialog.alert({
                    title: '错误',
                    message: '请求被拒绝',
                }).then(() => { });
                break;
            case 404:
                router.push({
                    path: '/404'
                })
                break;
            case 500:
                Dialog.alert({
                    title: '错误',
                    message: '服务器出错',
                }).then(() => { });
                break;

            default:
                break;
        }
    },
    error => {
        console.log(error);
        // Dialog.alert({
        //     title: '错误',
        //     message: error,
        // }).then(() => {
        //     // on close
        // });
    }
)

export default service