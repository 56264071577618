
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routesMap = [
    {
        path: '',
        redirect: '/agreement/detail/:id'
    },
    {
        path: '/home',
        component: () => import('@pages/home'),
    },
    {
        path: '/idcard',
        meta: { title: '身份信息', noCache: true },
        component: () => import('@pages/idCard'),
    },
    {
        path: '/clusterIdCard',
        meta: { title: '身份信息', noCache: true },
        component: () => import('@pages/clusterIdCard'),
    },
    {
        path: '/agreement/detail/:id',
        meta: { title: '用户协议', noCache: true },
        component: () => import('@pages/agreement'),
    },
    {
        path: '/agreementNoSign/detail/:id',
        meta: { title: '共享经济服务协议', noCache: true },
        component: () => import('@pages/agreementNoSign'),
    },
    {
        path: '/404',
        meta: { title: '404', noCache: true },
        component: () => import('@pages/404'),
    }
]

export default new Router({
    mode: 'hash',
    routes: routesMap
})
