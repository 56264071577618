import request from "./axios"

const post = function post(url,data) {
    return request({
      url: url,
      method: 'post',
      data
    })
}
const get = function get(url) {
    return request({
        url: url,
        method: 'get'
    })
}

export default{
    post,
    get
}